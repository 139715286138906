import { proxy } from "./proxy";

export default {
	host: proxy["/uat"].target,
	// 请求地址
	baseUrl: "http://172.31.1.253:12880/admin/",
	oss: {
		website: "https://suwa3d-test.oss-cn-shanghai.aliyuncs.com",
		"3dview": "https://suwa3d-test.oss-cn-shanghai.aliyuncs.com",
		webwechat: "https://suwa3d-test.oss-cn-shanghai.aliyuncs.com",
		securedata: "https://suwa3d-test.oss-cn-shanghai.aliyuncs.com"
	},
	msgWebsocket: {
		WEBSOCKETADDRESS: "ws://172.31.1.253:12880"
	},
	weightWs: "ws://localhost:10999/ws"
};
