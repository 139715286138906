import { getUrlParam, storage } from "../utils";
import { proxy } from "./proxy";

export default {
	// 根地址
	host: proxy["/dev"].target,

	// 请求地址
	get baseUrl() {
		let proxy = getUrlParam("proxy");

		if (proxy) {
			storage.set("proxy", proxy);
		} else {
			proxy = storage.get("proxy") || "dev";
		}
		// return "http://127.0.0.1:12880/admin";
		return "http://172.31.1.253:12880/admin";
		return `/${proxy}`;

	},
	oss: {
		website: "https://suwa3d-test.oss-cn-shanghai.aliyuncs.com",
		"3dview": "https://suwa3d-test.oss-cn-shanghai.aliyuncs.com",
		webwechat: "https://suwa3d-test.oss-cn-shanghai.aliyuncs.com",
		securedata: "https://suwa3d-test.oss-cn-shanghai.aliyuncs.com"
	},

	weightWs: "ws://localhost:12880/ws",

	msgWebsocket: {
		WEBSOCKETADDRESS: "ws://localhost:12880"
	}
};
